* {
  margin: 0;
}
.BigText {
  font-size: 900%;
  font-weight: 400%;
  color: white;
  margin-left: 60%;
}
.App-header {
  background-image: url("../public/Images/waterpic.jpg");
  background-size: cover;
  background-position: 50% 70%;
  height: 100vh;
  color: white;
  margin: 0;
}
.App-header h1 {
  margin-left: 200px;
  font-weight: 200%;
  font-size: 100px;
  transform: translateY(100px);
}
.bouton {
  margin: 0;
  margin-left: 200px;
  font-weight: 200%;
  font-size: 100px;
  margin-top: 70px;
  display: block;
  display: inline-block;
}
.LetterHeader {
  background-image: url("../public/Images/volley.jpg");
  background-size: cover;
  min-height: 70vh;
  background-position: 20% 35%;
}
.row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.WhoKevinHe1 {
  flex-basis: 48%;
  background-image: url("../public/Images/piano.jpg");
  background-size: cover;
  border-radius: 15px;
}
.WhoKevinHe2 {
  flex-basis: 50%;
}
.container {
  padding: 15px 5%;
}
/*-------------------------------------------------------- Introduction Letter  ----------------------------------*/
.page2header {
  font-size: 80px;
  font-weight: 200;
  color: white;
  position: relative;
  left: 57%;
  top: 9%;
}
.intro {
  background-color: aliceblue;
  padding: 20px 10%;
  margin: 2%;
}
.videocv {
  background-image: url("../public/Images/waterpic.jpg");
  background-size: cover;
  width: 100%;
  height: 100vh;
  background-position: center;
  justify-content: center;
}
/*-------------------------------------------------------- CV  ----------------------------------*/
.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

@media (max-width: 600px) {
  * {
    margin: 0;
  }
  .BigText {
    font-size: 300%; /* Adjusted for mobile */
    font-weight: 400%;
    color: white;
    margin-left: 10%; /* Adjusted for mobile */
  }
  .App-header {
    background-image: url("../public/Images/waterpic.jpg");
    background-size: cover;
    background-position: 50% 70%;
    height: 100vh;
    color: white;
    margin: 0;
  }
  .App-header h1 {
    margin-left: 4px;
    font-weight: 200%;
    font-size: 30px; /* Adjusted for mobile */
    transform: translateY(50px); /* Adjusted for mobile */
  }
  .bouton {
    margin: 100px auto 0 auto; /* Top, Right, Bottom, Left */
    font-weight: 200%;
    font-size: 30px; /* Adjusted for mobile */
    display: block; /* Maintains block level */
  }
  .LetterHeader {
    background-image: url("../public/Images/volley.jpg");
    background-size: cover;
    min-height: 50vh; /* Adjusted for mobile */
    background-position: 20% 35%;
  }
  .row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .WhoKevinHe2 {
    flex-basis: 100%; /* Adjusted for mobile */
  }
  .container {
    padding: 15px 5%;
  }
  .page2header {
    font-size: 40px; /* Adjusted for mobile */
    font-weight: 200;
    color: white;
    position: relative;
    left: 10%; /* Adjusted for mobile */
    top: 5%;
  }
  .intro {
    background-color: aliceblue;
    padding: 20px 5%; /* Adjusted for mobile */
    margin: 2%;
  }
  .videocv {
    background-image: url("../public/Images/waterpic.jpg");
    background-size: cover;
    width: 100%;
    height: 50vh; /* Adjusted for mobile */
    background-position: center;
    justify-content: center;
  }
}
